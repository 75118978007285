<template>
  <section id="settings">
    <div class="col1">
      <h3>Ajustes</h3>
      <p>Actualiza tu perfil</p>

      <transition name="fade">
        <p v-if="showSuccess" class="success">Perfil actualizado</p>
      </transition>

      <form @submit.prevent>
        <label for="name">Nombre</label>
        <input v-model.trim="name" type="text" :placeholder="userProfile.name" id="name" />

        <label for="lastName">Apellido</label>
        <input v-model.trim="lastName" type="text" :placeholder="userProfile.lastName" id="lastName" />

        <button @click="updateProfile()" class="button">Actualizar Perfil</button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      name: '',
      lastName: '',
      showSuccess: false
    }
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        name: this.name !== '' ? this.name : this.userProfile.name,
        lastName: this.lastName !== '' ? this.lastName : this.userProfile.lastName
      })

      this.name = ''
      this.lastName = ''

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    }
  }
}
</script>
